import React from "react"
import { Box, Heading } from "app/wellzesta-ui"
import { useOrganization } from "app/common/Organization"
import CurrentUserMenu from "../CurrentUserMenu"
import MoodCheckinButton from "app/features/mood-checkin/MoodCheckinButton"
import OrgLogo from "app/common/icons/OrgLogo"
import SearchInput from "app/common/SearchInput"

export function HeaderBar({ children }) {
  return (
    <header className="top-0 sticky z-20 bg-inverse-lighten text-black shadow-md">
      <div
        data-test-id="HeaderRow"
        className="py-3 px-3 md:px-4 mx-auto flex justify-between"
      >
        {children}
      </div>
    </header>
  )
}

function Header({ openSidebarButton }) {
  const organization = useOrganization()

  return (
    <HeaderBar>
      <div className="flex items-center min-w-0">
        {openSidebarButton}
        <Box className="mr-2 md:mr-3 flex-grow-0 flex-shrink-0 min-w-0">
          <OrgLogo
            className="w-8 h-8 rounded-md"
            logoUrl={
              organization?.id === "1"
                ? "/static/images/avafield.png"
                : organization.logoUrl
            }
          />
        </Box>
        <Heading size="sm" as="div" className="font-bold truncate">
          {organization?.name}
        </Heading>
      </div>

      <div className="flex items-center min-w-0 space-x-6">
        <div>
        <SearchInput
          defaultValue={""}
          // onChange={onSearch}
          placeholder="Search"
          className="h-full"
        />
        </div>
        
        <MoodCheckinButton />
        <CurrentUserMenu />
      </div>
    </HeaderBar>
  )
}

export default Header
