import { Button } from "app/wellzesta-ui"
import React from "react"
import { useMoodCheckinState } from "./MoodCheckinContext"

export default function MoodCheckinButton() {
  const { status, showDialog } = useMoodCheckinState()

  return (
    <Button
      className="is-dark ustify-center items-center text-lg font-bold px-5"
      onClick={showDialog}
      disabled={status !== "answering"}
    >
      Check-In
    </Button>
  )
}
