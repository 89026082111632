import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDialogState } from "reakit/Dialog"
import { gql, useApolloClient } from "@apollo/client"
import moment from "moment"
import { useCurrentUser } from "app/lib/CurrentUser"
import { useOrganization } from "app/common/Organization"

const MoodCheckinContext = React.createContext()

const todayMoodCheckinQuery = gql`
  query GetTodayCheckin($organizationId: ID!) {
    currentUser {
      id
      todayCheckin(organizationId: $organizationId) {
        id
        checkedOn
        lastCheckinAt
        mood
      }
    }
  }
`

function storeLastCheckin({ key, momentDate }) {
  window.localStorage.setItem(key, momentDate.toISOString())
}

export default function MoodCheckinProvider({ children }) {
  const [status, setStatus] = useState("fetching")
  const apolloClient = useApolloClient()
  const dialog = useDialogState({ modal: false })
  const currentUser = useCurrentUser()
  const organization = useOrganization()
  const [lastCheckinAt, setLastCheckinAt] = useState(null)
  const shouldQueryCheckin = currentUser.hasPermission("checkin:create")
  const [checkinRequested, setCheckinRequested] = useState(false)

  const showDialog = useCallback(() => {
    dialog.show()
  }, [dialog])

  /**
   * Avoid last checkin errors when accessing the app with different organizations/users on the same browser
   */
  const localStorageKey = useMemo(
    () => `${organization?.id}_${currentUser?.id}_last_checkin_at`,
    [currentUser?.id, organization?.id]
  )

  useEffect(() => {
    const today = moment()
    let timer

    if (status === "idle") {
      clearTimeout(timer)
      const oneHour = 60 * 60000
      timer = setTimeout(() => {
        setStatus("fetching")
      }, oneHour)
    }

    if (status === "fetching") {
      if (organization?.isChildless && shouldQueryCheckin) {
        apolloClient
          .query({
            query: todayMoodCheckinQuery,
            variables: { organizationId: organization?.id },
            fetchPolicy: "network-only",
          })
          .then(({ data }) => {
            const lastApiCheckinAt =
              data?.currentUser?.todayCheckin?.lastCheckinAt
            const skipCheckinAt = window.localStorage.getItem(localStorageKey)

            if (!lastApiCheckinAt && !skipCheckinAt) {
              return setStatus("answering")
            }

            if (
              !lastApiCheckinAt &&
              skipCheckinAt &&
              today.isAfter(moment(skipCheckinAt), "day")
            ) {
              return setStatus("answering")
            }

            setStatus("idle")
          })
      }
    }

    if (status === "answering" && checkinRequested) {
      return showDialog()
    }

    return () => clearTimeout(timer)
  }, [
    apolloClient,
    shouldQueryCheckin,
    localStorageKey,
    organization?.isChildless,
    showDialog,
    status,
    organization?.id,
    checkinRequested
  ])

  const onFinishCheckin = useCallback(() => {
    dialog.hide()
    const today = moment()
    if (!lastCheckinAt || !today.isAfter(moment(lastCheckinAt), "day")) {
      storeLastCheckin({ key: localStorageKey, momentDate: moment() })
    }
    setStatus("idle")
  }, [dialog, lastCheckinAt, localStorageKey])

  return (
    <MoodCheckinContext.Provider
      value={{
        dialogState: dialog,
        showDialog,
        finishCheckin: onFinishCheckin,
        setLastCheckinAt: setLastCheckinAt,
        lastCheckinAt,
        checkinRequested,
        requestCheckin: setCheckinRequested,
        status
      }}
    >
      {children}
    </MoodCheckinContext.Provider>
  )
}

export const useMoodCheckinState = () => React.useContext(MoodCheckinContext)
