import React from "react"
import { Link } from "app/wellzesta-ui"
import RouterLink from "next/link"

const InternalLink = React.forwardRef(
  (
    { children, href, as, replace, scroll, shallow, className, ...props },
    ref
  ) => {
    return (
      <RouterLink
        passHref
        as={as}
        href={href}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
      >
        <Link
          size="sm"
          ref={ref}
          className={`font-semibold ${className}`}
          {...props}
        >
          {children}
        </Link>
      </RouterLink>
    )
  }
)

export default InternalLink
