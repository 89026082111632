import React from "react"
import clsx from "clsx"
import { Input } from "app/wellzesta-ui"
import SearchIcon from "app/common/icons/Search"

export default function SearchInput({
  onChange,
  placeholder,
  className,
  ...props
}) {
  return (
    <div
      className={clsx(
        "relative flex items-center w-full min-h-action",
        className
      )}
    >
      <SearchIcon
        className="absolute w-6 h-auto ml-2 z-10 pointer-events-none"
        styles={{ top: "15px", left: "12px" }}
      />
      <Input
        {...props}
        type="search"
        placeholder={placeholder}
        className="pl-10 pr-3 focus:bg-white text-black"
        onChange={(e) => onChange(e.target.value)}
        settings={"bg-white"}
      />
    </div>
  )
}
